<template>
    <v-layout>
        <v-flex xs2>
            <v-btn
                fab
                dark
                @click="openComment"
            >
                <v-icon
                    dark
                >
                    comment
                </v-icon>
            </v-btn>
        </v-flex>
        <v-flex xs3 md3 offset-xs7 offset-md10>
            <v-radio-group v-model="radios" row :disabled="valid">
                <v-radio
                    value="Down"
                    class="radioLeft grow"
                    color="black"
                    @click="decrementSingle()"
                >
                    <template v-slot:label>
                        <div><strong class="error--text">-1</strong></div>
                    </template>
                </v-radio>
                <v-radio
                    value="Up"
                    color="black"
                    class="grow"
                    @click="incrementSingle()"
                >
                    <template v-slot:label>
                        <div><strong class="success--text">1</strong></div>
                    </template>
                </v-radio>
            </v-radio-group>
        </v-flex>
    </v-layout>
</template>

<script>

export default {
    name: 'radioButtons',
    data () {
        return {
            radios: '',
            clickedButton: ''
        }
    },
    props: {
        singleCount: {
            type: Number,
            default () {
                return 0
            }
        },
        valid: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    created () {
        if (this.singleCount === -1) {
            this.clickedButton = 'Down'
            this.radios = 'Down'
        } else if (this.singleCount === 1) {
            this.clickedButton = 'Up'
            this.radios = 'Up'
        }
    },
    methods: {
        openComment () {
            this.$emit('openComment')
        },
        unselectButtons () {
            this.clickedButton = ''
            this.radios = ''
            this.$emit('incrementSingle', {
                count: Number(0)
            })
        },
        incrementSingle () {
            if (this.clickedButton === 'Up') {
                this.unselectButtons()
            } else {
                this.clickedButton = 'Up'
                this.$emit('incrementSingle', {
                    count: Number(1)
                })
            }
        },
        decrementSingle () {
            if (this.clickedButton === 'Down') {
                this.unselectButtons()
            } else {
                this.clickedButton = 'Down'
                this.$emit('decrementSingle', {
                    count: Number(-1)
                })
            }
        }
    }
}
</script>
<style>
.radioLeft {
    padding-right: 15px;
}
.grow {
    transform: scale(1.7);
}
</style>
